import React from 'react';
import styles from '@src/scss/pages/404/styles.scss';

const Custom404 = () => {
    return (
        <main className={styles.errorMsg}>
            <h2>Cтраница не найдена </h2>
        </main>
    );
};

export default Custom404;
